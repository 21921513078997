import { cloneDeep, snakeCase } from "lodash-es";

import { streamTypes } from "shared/constants";
import DateRange from "shared/helpers/DateRange";
import {
  type MediumField,
  type MediumName,
  social,
  SocialPlatform,
} from "shared/helpers/media";
import {
  type Source,
  sourceGroupKeys,
  sourceKeysFor,
} from "shared/helpers/sourceFilters";
import features from "shared/services/features";
import type { Nullable, Stream } from "shared/types";
import type { MentionType, SocialMentionType } from "shared/types/mentions";

import {
  SortOptionField,
  SortOptionMissing,
  SortOptionOrder,
} from "./mentions";

export type StreamFiltersTag = {
  color: Nullable<string>;
  focus?: boolean;
  id: number;
  label: string;
  selected?: boolean;
};

export interface StreamFiltersSortOption {
  sortBy: SortOptionField;
  orderBy: SortOptionOrder;
  missing: SortOptionMissing;
}

type StreamFiltersAuthor = {
  focus?: boolean;
  id: number;
  name: string;
};

type StreamFiltersCategory = "National" | "Metro" | "Regional";

type StreamFiltersMinMax = {
  max: Nullable<number>;
  min: Nullable<number>;
};

type StreamFiltersFrontPageRanks = {
  min: number;
};

type StreamFiltersTier = {
  label: string;
  value: number;
};

type BaseStreamRequestFilters = {
  [key in `${MediumField}_tiers`]?: number[];
};

export interface StreamRequestFiltersSortOption {
  sort_by: SortOptionField;
  sort_order: SortOptionOrder;
  missing: SortOptionMissing;
}

export interface StreamRequestFilters extends BaseStreamRequestFilters {
  authors?: string;
  boolean_query?: string;
  bundle_broadcast?: boolean;
  categories?: StreamFiltersCategory[];
  collapse_syndicated?: boolean;
  excluded_keywords?: string;
  excluded_source_group_keys?: string[];
  excluded_source_keys?: string[];
  front_page_ranks?: StreamFiltersFrontPageRanks;
  include_bookmark_syndication?: boolean;
  include_external_items?: boolean;
  keywords?: string;
  lastSavedEnabled?: boolean;
  location_ids?: number[];
  max_aggregate_emotionality?: number;
  max_aggregate_fakeness?: number;
  max_aggregate_harmful?: number;
  max_aggregate_spam?: number;
  max_domain_authority?: number;
  max_react_score?: number;
  max_word_count?: number;
  media?: MediumName[];
  min_aggregate_emotionality?: number;
  min_aggregate_fakeness?: number;
  min_aggregate_harmful?: number;
  min_aggregate_spam?: number;
  min_domain_authority?: number;
  min_react_score?: number;
  min_word_count?: number;
  minimum_follower_count?: number;
  online_tiers?: number[];
  only_external_items?: boolean;
  page_numbers?: StreamFiltersMinMax;
  programs?: string[];
  range?: DateRange;
  sentiment?: StreamFiltersMinMax[];
  sort_options?: StreamRequestFiltersSortOption[];
  source_group_keys?: string[];
  source_keys?: string[];
  stock_symbol?: string;
  tag_ids?: number[];
  excluded_tag_ids?: number[];
  types?: string[];
  use_exact_authors?: boolean;
  verified?: boolean;
}

export type InitialStreamFilters = Partial<Omit<StreamFilters, "stream">>;

export interface RequestFilters {
  booleanQuery: StreamFilters["booleanQuery"];
  excluded_keywords: StreamRequestFilters["excluded_keywords"];
  excludedSources: StreamFilters["sources"];
  keywords: StreamRequestFilters["keywords"];
  max_word_count: StreamRequestFilters["max_word_count"];
  min_word_count: StreamRequestFilters["min_word_count"];
  page_numbers: StreamRequestFilters["page_numbers"];
  sources: StreamFilters["sources"];
}

export default class StreamFilters {
  aggregateEmotionality: StreamFiltersMinMax;

  aggregateFakeness: StreamFiltersMinMax;

  aggregateHarmful: StreamFiltersMinMax;

  aggregateSpam: StreamFiltersMinMax;

  authors: StreamFiltersAuthor[];

  booleanQuery: string;

  bundleBroadcast: boolean;

  categories: StreamFiltersCategory[];

  collapseSyndicated: boolean;

  dateRangeEnabled: boolean;

  domainAuthority?: StreamFiltersMinMax;

  excludedKeywords: string;

  excludedSources: Source[];

  excludedTags: StreamFiltersTag[];

  externalItemsEnabled: boolean;

  frontPageRanks: StreamFiltersFrontPageRanks;

  frontPageRanksEnabled: boolean;

  includeBookmarkSyndication: boolean;

  includedMentionsEnabled: boolean;

  initialFilters: InitialStreamFilters;

  keywords: string;

  lastSavedEnabled: boolean;

  locations: number[];

  media: MediumName[];

  mediaTypes: MentionType[];

  minimumFollowerCount: number;

  minimumFollowerCountEnabled: boolean;

  onlyShowExternalItems: boolean;

  pageNumbers: StreamFiltersMinMax;

  pageNumbersEnabled: boolean;

  platforms: SocialPlatform[];

  programs: string[];

  range: DateRange;

  reactScore: StreamFiltersMinMax;

  reactScoreAverageSelected: true;

  reactScoreBreakdown: boolean;

  sentiment: StreamFiltersMinMax[];

  sentimentEnabled: boolean;

  sources: Source[];

  sortOptions: StreamFiltersSortOption[];

  stockSymbol?: string;

  stream: Nullable<Stream>;

  tags: StreamFiltersTag[];

  tiers: StreamFiltersTier[];

  useExactAuthors: boolean;

  verifiedEnabled: boolean;

  wordCount: StreamFiltersMinMax;

  wordCountEnabled: boolean;

  static get DEFAULT_FILTERS(): InitialStreamFilters {
    const streamFilters = new StreamFilters(null);

    return streamFilters.initialFilters;
  }

  static fromRequestFilters(requestFilters: RequestFilters): StreamFilters {
    const {
      booleanQuery,
      excluded_keywords: excludedKeywords,
      excludedSources,
      keywords,
      max_word_count: maxWordCount,
      min_word_count: minWordCount,
      page_numbers: pageNumbers,
      sources,
    } = requestFilters;

    let pageNumbersEnabled = false;

    if (pageNumbers !== undefined) {
      pageNumbersEnabled = true;
    }

    let wordCountEnabled = false;

    let wordCount = {
      min: 0,
      max: 0,
    };

    if (minWordCount !== undefined && maxWordCount !== undefined) {
      wordCountEnabled = true;

      wordCount = {
        min: minWordCount,
        max: maxWordCount,
      };
    }

    return new StreamFilters(null, {
      booleanQuery,
      excludedKeywords,
      excludedSources,
      keywords,
      pageNumbers,
      pageNumbersEnabled,
      sources,
      wordCount,
      wordCountEnabled,
    });
  }

  constructor(
    stream: Nullable<Stream>,
    initialFilters: InitialStreamFilters = {}
  ) {
    this.stream = stream;
    this.booleanQuery = "";
    this.keywords = "";
    this.excludedKeywords = "";
    this.sources = [];
    this.tags = [];
    this.excludedTags = [];
    this.excludedSources = [];
    this.authors = [];
    this.categories = [];
    this.locations = [];
    this.tiers = [];
    this.sentiment = [{ min: -1, max: 1 }];

    this.wordCount = {
      min: 0,
      max: 3000,
    };

    this.onlyShowExternalItems = false;
    this.range = DateRange.today();
    this.media = [];
    this.mediaTypes = [];
    this.platforms = [];
    this.verifiedEnabled = false;
    this.minimumFollowerCountEnabled = false;
    this.minimumFollowerCount = 500;
    this.collapseSyndicated = false;
    this.bundleBroadcast = false;
    this.programs = [];
    this.frontPageRanks = { min: 0 };
    this.pageNumbers = { min: 0, max: 0 };
    this.wordCountEnabled = false;
    this.dateRangeEnabled = false;
    this.sentimentEnabled = false;
    this.lastSavedEnabled = false;
    this.includedMentionsEnabled = false;
    this.frontPageRanksEnabled = false;
    this.pageNumbersEnabled = false;
    this.reactScoreAverageSelected = true;
    this.reactScoreBreakdown = false;
    this.includeBookmarkSyndication = false;

    this.sortOptions = [];

    this.reactScore = {
      min: 0,
      max: 100,
    };

    this.aggregateEmotionality = {
      min: 0,
      max: 100,
    };

    this.aggregateFakeness = {
      min: 0,
      max: 100,
    };

    this.aggregateSpam = {
      min: 0,
      max: 100,
    };

    this.aggregateHarmful = {
      min: 0,
      max: 100,
    };

    this.useExactAuthors = false;

    if (
      this.canFilterExternalItems() &&
      !("externalItemsEnabled" in initialFilters)
    ) {
      this.externalItemsEnabled = true;
    }

    Object.assign(this, initialFilters);

    const streamFiltersClone = cloneDeep(this);

    const {
      stream: streamClone,
      initialFilters: initialFiltersClone,
      ...filters
    } = streamFiltersClone;

    this.initialFilters = cloneDeep(filters);
  }

  clone(): StreamFilters {
    return Object.assign(new StreamFilters(this.stream, cloneDeep(this)), {
      initialFilters: cloneDeep(this.initialFilters),
    });
  }

  supportsExternalItems(stream: Nullable<Stream>): boolean {
    if (stream === null || stream === undefined) return false;

    return [
      streamTypes.mentionStream,
      streamTypes.bookmarkStream,
      streamTypes.organisationBrief,
    ].includes(stream.type as number);
  }

  canFilterExternalItems(): boolean {
    if (this.stream === null) {
      return false;
    }

    return (
      this.supportsExternalItems(this.stream) &&
      features.has("has_external_items")
    );
  }

  filteringContent(
    { ignoredFilters }: { ignoredFilters: string[] } = { ignoredFilters: [] }
  ): boolean {
    const contentFilters = {
      keywords: this.keywords && this.keywords !== "",
      excludedKeywords: this.excludedKeywords && this.excludedKeywords !== "",
      pageNumber:
        this.pageNumbersEnabled !== this.initialFilters.pageNumbersEnabled,
      wordCount: this.wordCountEnabled !== this.initialFilters.wordCountEnabled,
      externalItems:
        this.externalItemsEnabled !== this.initialFilters.externalItemsEnabled,
      dateRangeEnabled:
        this.dateRangeEnabled !== this.initialFilters.dateRangeEnabled,
      dateRange:
        this.initialFilters.range &&
        !this.range.equals(this.initialFilters.range),
      sentiment:
        this.sentimentEnabled !== this.initialFilters?.sentimentEnabled,
      lastSaved: this.lastSavedEnabled,
    };

    return Object.entries(contentFilters).some(
      ([filter, active]) => !ignoredFilters.includes(filter) && active
    );
  }

  rangeEquals(
    selected: StreamFiltersMinMax,
    initial: StreamFiltersMinMax
  ): boolean {
    return selected.min === initial.min && selected.max === initial.max;
  }

  filteringReactScore(): boolean {
    if (this.reactScoreBreakdown) {
      return true;
    }

    const reactScoreFilters = [
      this.reactScoreAverageSelected &&
        this.initialFilters?.reactScore &&
        !this.rangeEquals(this.reactScore, this.initialFilters?.reactScore),
      !this.reactScoreAverageSelected &&
        this.initialFilters?.aggregateEmotionality &&
        !this.rangeEquals(
          this.aggregateEmotionality,
          this.initialFilters?.aggregateEmotionality
        ),
      !this.reactScoreAverageSelected &&
        this.initialFilters.aggregateFakeness &&
        !this.rangeEquals(
          this.aggregateFakeness,
          this.initialFilters.aggregateFakeness
        ),
      !this.reactScoreAverageSelected &&
        this.initialFilters.aggregateSpam &&
        !this.rangeEquals(
          this.aggregateSpam,
          this.initialFilters.aggregateSpam
        ),
      !this.reactScoreAverageSelected &&
        this.initialFilters.aggregateHarmful &&
        !this.rangeEquals(
          this.aggregateHarmful,
          this.initialFilters.aggregateHarmful
        ),
    ];

    return reactScoreFilters.some((filter) => filter);
  }

  filteringSources(): boolean {
    const sourceFilters = [
      this.sources.length,
      this.excludedSources.length,
      this.categories.length,
      this.tiers?.length,
    ];

    return sourceFilters.some((filter) => filter);
  }

  filteringAuthors(): boolean {
    return Boolean(this.authors.length);
  }

  filteringPlatforms(): boolean {
    return Boolean(this.platforms.length);
  }

  filteringHandleOrSocialImpact(): boolean {
    const filters = [
      this.authors.length,
      this.verifiedEnabled !== this.initialFilters.verifiedEnabled,
      this.minimumFollowerCountEnabled !==
        this.initialFilters.minimumFollowerCountEnabled,
    ];

    return filters.some((filter) => filter);
  }

  filteringTags(): boolean {
    const tagFilters = [this.tags.length, this.excludedTags.length];

    return tagFilters.some((filter) => filter);
  }

  filteringLocations(): boolean {
    return Boolean(this.locations.length);
  }

  filteringMedia(defaultMedia: MediumName[]): boolean {
    return this.media.length
      ? defaultMedia.length - this.media.length > 0
      : false;
  }

  platformsToMediaTypes(platforms: SocialPlatform[]): SocialMentionType[] {
    const mediaTypes: SocialMentionType[] = [];

    social.platforms.forEach((platform) => {
      if (platforms.includes(platform.platform)) {
        mediaTypes.push(...platform.fields);
      }
    });

    return mediaTypes;
  }

  requestFilters(): StreamRequestFilters {
    const params: StreamRequestFilters = {};

    if (this.keywords !== "") params.keywords = this.keywords;
    if (this.excludedKeywords !== "")
      params.excluded_keywords = this.excludedKeywords;
    if (this.booleanQuery !== "") params.boolean_query = this.booleanQuery;
    if (this.sources?.length) params.source_keys = sourceKeysFor(this.sources);
    if (this.sources?.length)
      params.source_group_keys = sourceGroupKeys(this.sources);
    if (this.tags.length > 0) params.tag_ids = this.tags.map((tag) => tag.id);

    if (this.excludedTags?.length) {
      params.excluded_tag_ids = this.excludedTags.map(
        (excludedTag) => excludedTag.id
      );
    }

    if (this.excludedSources?.length > 0) {
      params.excluded_source_keys = sourceKeysFor(this.excludedSources);
      params.excluded_source_group_keys = sourceGroupKeys(this.excludedSources);
    }

    if (this.authors.length) {
      params.authors = this.authors
        .map((author) => author.name || author)
        .join(", ");
    }

    if (
      this.wordCountEnabled &&
      this.wordCount.min !== null &&
      this.wordCount.max !== null
    ) {
      params.min_word_count = this.wordCount.min;
      params.max_word_count = this.wordCount.max;
    }

    if (this.externalItemsEnabled) {
      params.include_external_items = this.externalItemsEnabled;

      if (this.externalItemsEnabled) {
        params.only_external_items = this.onlyShowExternalItems;
      }
    }

    if (this.locations.length) {
      params.location_ids = this.locations;
    }

    if (this.dateRangeEnabled) {
      params.range = this.range;
    }

    if (this.sentimentEnabled) {
      params.sentiment = this.sentiment;
    }

    if (this.media.length > 0) params.media = this.media;

    if (this.platforms.length > 0) {
      params.types = this.platformsToMediaTypes(this.platforms);
    } else if (this.mediaTypes.length > 0) {
      params.types = this.mediaTypes;
    }

    if (this.verifiedEnabled) params.verified = this.verifiedEnabled;

    if (this.minimumFollowerCountEnabled)
      params.minimum_follower_count = this.minimumFollowerCount;

    if (this.categories.length) params.categories = this.categories;

    if (this.lastSavedEnabled) params.lastSavedEnabled = this.lastSavedEnabled;

    if (this.collapseSyndicated)
      params.collapse_syndicated = this.collapseSyndicated;

    if (this.bundleBroadcast) params.bundle_broadcast = this.bundleBroadcast;

    if (this.tiers.length) {
      const selectedTiers = this.tiers.map((tier) => tier.value);

      if (this.media.length > 0) {
        this.media.forEach((medium) => {
          params[`${snakeCase(medium) as MediumField}_tiers`] = selectedTiers;
        });
      } else if (this.stream !== null) {
        this.stream.enabled_media?.forEach((medium) => {
          params[`${snakeCase(medium) as MediumField}_tiers`] = selectedTiers;
        });
      }
    }

    if (
      this.stream?.type === streamTypes.bookmarkStream &&
      this.includeBookmarkSyndication
    ) {
      params.include_bookmark_syndication = this.includeBookmarkSyndication;
    }

    if (this.programs.length) params.programs = this.programs;

    if (this.frontPageRanksEnabled)
      params.front_page_ranks = this.frontPageRanks;

    if (
      this.pageNumbersEnabled &&
      this.pageNumbers.min !== null &&
      this.pageNumbers.max !== null
    ) {
      params.page_numbers = this.pageNumbers;
    }

    if (
      this.stream &&
      this.stream.type !== streamTypes.socialStream &&
      this.filteringReactScore()
    ) {
      if (
        this.reactScoreAverageSelected &&
        this.validateMinMax(this.reactScore)
      ) {
        params.min_react_score = this.reactScore.min / 100;
        params.max_react_score = this.reactScore.max / 100;
      } else {
        if (this.validateMinMax(this.aggregateEmotionality)) {
          params.min_aggregate_emotionality =
            this.aggregateEmotionality.min / 100;

          params.max_aggregate_emotionality =
            this.aggregateEmotionality.max / 100;
        }

        if (this.validateMinMax(this.aggregateFakeness)) {
          params.min_aggregate_fakeness = this.aggregateFakeness.min / 100;
          params.max_aggregate_fakeness = this.aggregateFakeness.max / 100;
        }

        if (this.validateMinMax(this.aggregateSpam)) {
          params.min_aggregate_spam = this.aggregateSpam.min / 100;
          params.max_aggregate_spam = this.aggregateSpam.max / 100;
        }

        if (this.validateMinMax(this.aggregateHarmful)) {
          params.min_aggregate_harmful = this.aggregateHarmful.min / 100;
          params.max_aggregate_harmful = this.aggregateHarmful.max / 100;
        }
      }
    }

    if (this.useExactAuthors) params.use_exact_authors = this.useExactAuthors;

    if (
      this.domainAuthority &&
      Object.keys(this.domainAuthority).length &&
      this.domainAuthority.min !== null &&
      this.domainAuthority.max !== null
    ) {
      params.min_domain_authority = this.domainAuthority.min;
      params.max_domain_authority = this.domainAuthority.max;
    }

    if (this.stockSymbol) params.stock_symbol = this.stockSymbol;

    if (this.sortOptions.length) {
      params.sort_options = this.sortOptions.map((option) => ({
        sort_by: option.sortBy,
        sort_order: option.orderBy,
        missing: option.missing,
      }));
    }

    return params;
  }

  validateMinMax(
    minMax: StreamFiltersMinMax
  ): minMax is { [K in keyof StreamFiltersMinMax]: number } {
    return minMax.max !== null && minMax.min !== null;
  }
}
