<template>
  <div
    ref="inputSelect"
    class="input-select"
    :class="{ 'new-design': newDesign, open: showAutocompleteOptions }"
  >
    <InputText
      ref="inputText"
      v-click-outside="clickOutsideConfig"
      :model-value="displayText"
      :placeholder="placeholder"
      :label="label"
      :description="description"
      :clearable="clearable"
      :disabled="disabled"
      :new-design="newDesign"
      :validations="validations"
      :readonly="!useInput"
      :auto-focus="autoFocus"
      class="relative"
      @click.stop="onClick"
      @focus="onFocus"
      @blur="onBlur"
      @update:model-value="handleInput"
      @clear="clear"
      @submit="submit"
      @keyup.stop="handleAutoFill"
    >
      <template
        v-for="(_, name) in inputTextSlots($slots)"
        #[name]="slotData: any"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
      <template
        v-if="!hideAutoComplete && !hideDropdownButton"
        #append
      >
        <slot name="append">
          <template v-if="newDesign">
            <BaseButton
              color="form"
              active-color="secondary"
              :active="focused"
              :icon="dropdownIconName"
              :loading="loading"
              :disabled="disabled"
              new-design
              @click.stop="onClick"
            />
          </template>

          <template v-else>
            <QIcon
              :name="dropdownIconName"
              size="16px"
              class="cursor-pointer"
              @click.stop="onClick"
            />

            <QSpinner v-if="loading" />
          </template>
        </slot>
      </template>

      <template #after>
        <Popper
          v-if="!hideAutoComplete"
          v-model="showAutocompleteOptions"
          :root-element="inputSelect"
          placement="bottom-start"
          :same-width="!popperWidth"
          :width="popperWidth"
        >
          <AutocompleteList
            v-model:show="showAutocompleteOptions"
            v-click-outside="clickOutsideConfig"
            :selection="selection"
            :options="options"
            :option-prop="optionProp"
            :option-label="optionLabel"
            :multiple="Boolean(multiple)"
            @select="selectOption"
          >
            <template
              v-for="(_, name) in autocompleteListSlots($slots)"
              #[name]="slotData: any"
            >
              <slot
                :name="name"
                v-bind="slotData"
              />
            </template>
          </AutocompleteList>
        </Popper>
      </template>
      <template #errors>
        <div
          v-if="errors.length"
          class="field-error q-mt-sm"
        >
          {{ errors[0] }}
        </div>
      </template>
    </InputText>
  </div>
</template>

<script lang="ts">
interface InputSelectOption {
  notSelectable?: boolean;
}
</script>

<script
  setup
  lang="ts"
  generic="
    T extends GenericOption & InputSelectOption,
    IsEmittingValue extends boolean = false,
    IsEmittingMultiple extends boolean = false
  "
>
import vClickOutsidePlugin, {
  type ClickOutsideVue3DirectiveOptions,
} from "click-outside-vue3";
import { computed, nextTick, ref, type SlotsType, toRef, watch } from "vue";
import type {
  ComponentExposed,
  ComponentSlots,
} from "vue-component-type-helpers";

import AutocompleteList, {
  type AutocompleteListProps,
  type GenericOption,
  isOptionObject,
} from "shared/components/base/AutocompleteList.vue";
import BaseButton from "shared/components/base/BaseButton.vue";
import InputText from "shared/components/base/InputText.vue";
import Popper from "shared/components/base/Popper.vue";
import useValidation, { Validations } from "shared/composables/useValidation";

interface Props extends Omit<AutocompleteListProps<T>, "selection"> {
  validations?: Validations;
  optional?: boolean;
  label?: string;
  description?: string;
  placeholder?: string;
  dropdownText?: string;
  popperWidth?: number | null;
  selectedText?: ((count: number) => string) | null;
  debounce?: number;
  useInput?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  emitValue?: IsEmittingValue | boolean;
  newDesign?: boolean;
  closeOnSelect?: boolean;
  autoFocus?: boolean;
  remoteLoading?: boolean;
  hideAutoComplete?: boolean;
  hideDropdownButton?: boolean;
  preventClearInputOnSubmit?: boolean;
  multiple?: IsEmittingMultiple | boolean;
}

type EmittedValueType = T extends object ? T[keyof T] : never;

type BaseValueType = IsEmittingValue extends true ? EmittedValueType : T;

type ModelType = IsEmittingMultiple extends true
  ? BaseValueType[]
  : BaseValueType;

type AutocompleteListSlots = ComponentSlots<typeof AutocompleteList<T>>;
type InputTextSlots = ComponentSlots<typeof InputText>;

function isEmittedValueArray(
  value: T | T[] | EmittedValueType | EmittedValueType[] | null
): value is EmittedValueType[] {
  return props.emitValue === true && Array.isArray(value);
}

function isBaseValueArray(
  value: BaseValueType | BaseValueType[] | null
): value is BaseValueType[] {
  return Array.isArray(value);
}

const model = defineModel<ModelType | null>({
  default: null,
});

const props = withDefaults(defineProps<Props>(), {
  validations: () => [],
  options: () => [],
  optionProp: "id",
  optionLabel: "",
  label: "",
  description: "",
  placeholder: "",
  dropdownText: "",
  popperWidth: null,
  selectedText: null,
  debounce: 300,
});

const emit = defineEmits<{
  focus: [];
  blur: [];
  click: [];
  "dropdown-closed": [];
  filter: [string];
  submit: [string];
  clear: [];
  selected: [T];
}>();

defineSlots<AutocompleteListSlots & InputTextSlots>();

const { directive: vClickOutside } = vClickOutsidePlugin;

const { errors, dirty, isValid, validate, reset } = useValidation({
  modelValue: model,
  validations: toRef(() => props.validations),
  optional: toRef(() => props.optional),
});

const showAutocompleteOptions = ref(false);
const focused = ref(false);
const inputTimer = ref<ReturnType<typeof setTimeout>>();
const filter = ref("");

const inputSelect = ref<HTMLDivElement>();
const inputText = ref<ComponentExposed<typeof InputText>>();

function hideList() {
  if (props.disabled) return;

  showAutocompleteOptions.value = false;
  emit("dropdown-closed");
}

const clickOutsideConfig = computed<ClickOutsideVue3DirectiveOptions>(() => ({
  capture: true,
  handler: hideList,
  events: ["mousedown"],
  isActive: showAutocompleteOptions.value,
  middleware: (event) => {
    if (inputText.value?.el?.contains(event.target as HTMLElement)) {
      return false;
    }

    return true;
  },
}));

const dropdownIconName = computed(() => {
  if (props.newDesign)
    return showAutocompleteOptions.value
      ? "keyboard_arrow_up"
      : "keyboard_arrow_down";

  return showAutocompleteOptions.value ? "arrow_drop_up" : "arrow_drop_down";
});

function inputTextSlots(slots: SlotsType) {
  return slots as InputTextSlots;
}

function autocompleteListSlots(slots: SlotsType) {
  return slots as AutocompleteListSlots;
}

const selectedOption = computed(() => {
  const modelValue = model.value;

  if (!props.emitValue) {
    return modelValue as T | T[];
  }

  const { optionProp } = props;

  if (props.multiple && isEmittedValueArray(modelValue) && optionProp) {
    return props.options.filter((option) => {
      if (!isOptionObject(option)) return false;

      return modelValue.includes(option[optionProp]);
    });
  }

  return props.options?.find((option) => {
    if (!isOptionObject(option) || !optionProp) return false;

    return option[optionProp] === modelValue;
  });
});

const displayText = computed(() => {
  if (props.dropdownText) return props.dropdownText;

  if (filter.value) return filter.value;

  if (
    model.value === null ||
    (props.multiple && isBaseValueArray(model.value) && !model.value.length)
  )
    return "";

  if (
    props.multiple &&
    isBaseValueArray(model.value) &&
    model.value.length &&
    !props.useInput
  ) {
    return props.selectedText
      ? props.selectedText(model.value.length)
      : `${model.value.length} selected`;
  }

  const selected = selectedOption.value;

  if (
    props.optionLabel &&
    selected &&
    !props.multiple &&
    !Array.isArray(selected)
  ) {
    if (typeof props.optionLabel === "function") {
      return props.optionLabel(selected);
    }

    if (isOptionObject(selected)) {
      return selected[props.optionLabel];
    }
  }

  if (selected && !props.optionLabel && !props.multiple) {
    return selected;
  }

  return filter.value;
});

const selection = computed({
  get() {
    return model.value;
  },
  set(value) {
    let inputValue = value;

    if (inputValue !== null) {
      const { optionProp } = props;

      if (props.emitValue && optionProp) {
        if (isEmittedValueArray(value) && props.multiple) {
          inputValue = value.map((opt: EmittedValueType) =>
            isOptionObject(opt) ? opt[optionProp] : opt
          );
        } else if (isOptionObject(value)) {
          inputValue = value[optionProp];
        }
      }
    }

    model.value = inputValue;
  },
});

function focus() {
  inputText.value?.el?.focus();
}

function blur() {
  inputText.value?.el?.blur();
}

async function selectOption(option: T) {
  if (isOptionObject(option) && option.notSelectable) return;

  const selectionValue = selection.value;

  if (props.multiple && isBaseValueArray(selectionValue)) {
    const index = selectionValue.findIndex((opt: BaseValueType) =>
      props.optionProp && isOptionObject(option) && isOptionObject(opt)
        ? opt[props.optionProp] === option[props.optionProp]
        : JSON.stringify(opt) === JSON.stringify(option)
    );

    if (index !== -1) {
      selection.value = selectionValue.filter((opt: BaseValueType) =>
        props.optionProp && isOptionObject(option) && isOptionObject(opt)
          ? opt[props.optionProp] !== option[props.optionProp]
          : JSON.stringify(opt) !== JSON.stringify(option)
      );
    } else {
      selection.value = [...selectionValue, option] as ModelType;
    }

    if (props.closeOnSelect || !props.multiple) {
      blur();
      hideList();
    }
  } else {
    selection.value = option as ModelType;
    await nextTick();

    if (props.closeOnSelect || !props.multiple) {
      blur();
      hideList();
    }
  }

  emit("selected", option);

  if (props.closeOnSelect || !props.multiple) {
    handleInput("");
  }
}

function handleAutoFill(event: KeyboardEvent) {
  if (!event.key) {
    const value = props.options.find((option) => {
      if (!isOptionObject(option) || typeof props.optionLabel !== "string")
        return false;

      return option[props.optionLabel]
        .toLowerCase()
        .includes((event.target as HTMLInputElement).value.toLowerCase());
    });

    if (value) {
      selectOption(value);
    }
  }
}

function onFocus() {
  focused.value = true;
  emit("focus");
}

function onBlur() {
  focused.value = false;
  emit("blur");
}

function onClick() {
  emit("click");
  toggleList();
}

function clear() {
  selection.value = props.multiple ? ([] as ModelType) : null;
  filter.value = "";
  hideList();
  emit("clear");
}

function toggleList() {
  if (showAutocompleteOptions.value) {
    hideList();
  } else {
    showList();
  }
}

function showList() {
  if (props.disabled) return;

  showAutocompleteOptions.value = true;
}

function handleInput(event: string | null) {
  clearTimeout(inputTimer.value);

  const value = event || "";

  filter.value = value;

  if (props.useInput) {
    inputTimer.value = setTimeout(() => {
      emit("filter", value);

      if (event) {
        showList();
      }
    }, props.debounce);
  }
}

function submit() {
  emit("submit", filter.value);

  if (!props.preventClearInputOnSubmit) {
    handleInput("");
  }

  if (!props.multiple) {
    blur();
    hideList();
  }
}

watch(showAutocompleteOptions, (value) => {
  focused.value = value;
});

watch(
  () => props.options,
  () => {
    if (focused.value) {
      showList();
    }
  },
  { deep: true }
);

watch(
  model,
  () => {
    handleInput("");
  },
  { deep: true }
);

defineExpose({
  isValid,
  validate,
  reset,
  focus,
  blur,
  hideList,
  errors,
  dirty,
  toggleList,
});
</script>
