<template>
  <div
    class="e2e-expandable-filters tw-mb-4 tw-flex tw-flex-col tw-bg-white tw-text-base tw-leading-none tw-text-denim-700"
    :class="{
      'show-title-header tw-fixed tw-left-0 tw-top-0 tw-z-[2000]':
        stickyFilters,
    }"
  >
    <div class="tw-flex-1 tw-overflow-auto tw-text-denim-700">
      <BaseExpansionPanel
        v-model="expandedPanels.content"
        :group="expandableGroupLabel"
        default-opened
        :label="getLocaleText('expandable_filters.content')"
        bold-label
        :error-message="displayContent"
        class="e2e-content-expandable"
        :class="{ 'tw-text-denim-900': displayActive('content') }"
      >
        <div class="-tw-mt-2">
          <ContentExpandable
            v-model:keywords="localFilters.keywords"
            v-model:excluded-keywords="localFilters.excludedKeywords"
            v-model:page-numbers="localFilters.pageNumbers"
            v-model:page-numbers-enabled="localFilters.pageNumbersEnabled"
            v-model:word-count-enabled="localFilters.wordCountEnabled"
            v-model:word-count="localFilters.wordCount"
            v-model:date-range-enabled="localFilters.dateRangeEnabled"
            v-model:selected-date-range="localFilters.range"
            v-model:external-items-enabled="localFilters.externalItemsEnabled"
            v-model:only-show-external-items="
              localFilters.onlyShowExternalItems
            "
            v-model:sentiment-enabled="localFilters.sentimentEnabled"
            v-model:sentiment="localFilters.sentiment"
            v-model:last-saved-enabled="localFilters.lastSavedEnabled"
            v-model:included-mentions-enabled="
              localFilters.includedMentionsEnabled
            "
            :show-included-mentions="showIncludedMentions"
            :stream="stream"
            :filters="localFilters"
            :hide-date-range="hideDateRange"
            :show-last-saved-toggle="showLastSavedToggle"
            :hide-word-count="isSocialStream"
            :hide-page-numbers="isSocialStream"
            @search="applySearch"
          />
        </div>
      </BaseExpansionPanel>

      <BaseSeparator v-if="showReactScoreFilters" />

      <BaseExpansionPanel
        v-if="showReactScoreFilters"
        v-model="expandedPanels.reactScore"
        :label="getLocaleText('expandable_filters.react_score')"
        bold-label
        :group="expandableGroupLabel"
        :error-message="displayReactScore"
        :class="{ 'tw-text-denim-900': displayActive('reactScore') }"
        class="e2e-risk-scores-expandable"
      >
        <ReactScoreExpandable
          v-model:react-score="localFilters.reactScore"
          v-model:react-score-average-selected="
            localFilters.reactScoreAverageSelected
          "
          v-model:aggregate-emotionality="localFilters.aggregateEmotionality"
          v-model:aggregate-fakeness="localFilters.aggregateFakeness"
          v-model:aggregate-spam="localFilters.aggregateSpam"
          v-model:aggregate-harmful="localFilters.aggregateHarmful"
          @search="applySearch"
        />
      </BaseExpansionPanel>

      <BaseSeparator v-if="!isSocialStream" />

      <BaseExpansionPanel
        v-if="!isSocialStream"
        v-model="expandedPanels.sources"
        :label="getLocaleText('expandable_filters.source')"
        :group="expandableGroupLabel"
        bold-label
        :error-message="displaySources"
        :class="{ 'tw-text-denim-900': displayActive('sources') }"
        class="e2e-sources-expandable"
        expand-icon-class="text-ds-denim-9"
      >
        <SourcesExpandable
          v-model:sources="localFilters.sources"
          v-model:excluded-sources="localFilters.excludedSources"
          v-model:authors="localFilters.authors"
          v-model:categories="localFilters.categories"
          v-model:tiers="localFilters.tiers"
          :label="getLocaleText('expandable_filters.included_sources')"
          :stream="stream"
          :show-categories="showCategories"
          :show-tiers="showTiers"
          @search="applySearch"
        />
      </BaseExpansionPanel>

      <BaseSeparator />

      <BaseExpansionPanel
        v-if="isSocialStream"
        v-model="expandedPanels.platforms"
        :label="getLocaleText('expandable_filters.social_platform')"
        :group="expandableGroupLabel"
        bold-label
        :error-message="displayPlatforms"
        :class="{ 'tw-text-denim-900': displayActive('platforms') }"
        class="e2e-platforms-expandable"
      >
        <SocialPlatformsExpandable
          v-model:platforms="handlePlatforms"
          v-model:refresh-platforms="refreshPlatforms"
          :stream="stream"
          :verified-enabled="localFilters.verifiedEnabled"
          :minimum-follower-count-enabled="
            localFilters.minimumFollowerCountEnabled
          "
          :minimum-follower-count="localFilters.minimumFollowerCount"
          inline
          @search="applySearch"
        />
      </BaseExpansionPanel>

      <BaseExpansionPanel
        v-else
        v-model="expandedPanels.media"
        :label="getLocaleText('expandable_filters.media_type')"
        :group="expandableGroupLabel"
        bold-label
        :error-message="displayMedia"
        :class="{ 'tw-text-denim-900': displayActive('media') }"
        class="e2e-media-expandable"
      >
        <MediaSelector
          v-model:media="localFilters.media"
          v-model:refresh-media="refreshMedia"
          :stream="stream"
          @search="applySearch"
        />
      </BaseExpansionPanel>

      <BaseSeparator v-if="isSocialStream" />

      <BaseExpansionPanel
        v-if="isSocialStream"
        v-model="expandedPanels.socialImpact"
        :group="expandableGroupLabel"
        :label="getLocaleText('expandable_filters.author')"
        bold-label
        :error-message="displayHandleOrSocialImpact"
        :class="{ 'tw-text-denim-900': displayActive('socialImpact') }"
        expand-icon-class="text-ds-denim-9"
      >
        <AuthorsExpandable
          v-model:authors="localFilters.authors"
          content-style="bold"
          @search="applySearch"
        />

        <SocialImpactExpandable
          v-model:minimum-follower-count-enabled="
            localFilters.minimumFollowerCountEnabled
          "
          v-model:minimum-follower-count="localFilters.minimumFollowerCount"
          v-model:verified-enabled="localFilters.verifiedEnabled"
          span-class="bold block q-mt-sm"
          @search="applySearch"
        />
      </BaseExpansionPanel>

      <BaseSeparator />

      <BaseExpansionPanel
        v-if="isStream(stream)"
        v-model="expandedPanels.tags"
        :label="getLocaleText('expandable_filters.tag')"
        :group="expandableGroupLabel"
        bold-label
        :error-message="displayTags"
        :class="{ 'tw-text-denim-900': displayActive('tags') }"
      >
        <DropdownTags
          v-model:tags="localFilters.tags"
          :stream="stream"
          :hide-input="Boolean(localFilters.excludedTags.length)"
          :hide-input-label="getLocaleText('dropdown_tags.tags_error')"
          class="-tw-mt-3 tw-mb-4"
          @search="applySearch"
        />

        <div class="tw-flex tw-flex-col tw-gap-2">
          <div class="tw-font-bold">
            {{ getLocaleText("dropdown_tags.excluded_tags") }}
          </div>

          <DropdownTags
            v-model:tags="localFilters.excludedTags"
            :stream="stream"
            :hide-input="Boolean(localFilters.tags.length)"
            :hide-input-label="
              getLocaleText('dropdown_tags.exclude_tags_error')
            "
            @search="applySearch"
          />
        </div>
      </BaseExpansionPanel>

      <BaseSeparator />

      <BaseExpansionPanel
        v-if="includeSorting"
        v-model="expandedPanels.sorting"
        :label="getLocaleText('expandable_filters.sorting')"
        :group="expandableGroupLabel"
        bold-label
        :error-message="displaySorting"
        :class="{ 'tw-text-denim-900': displayActive('sorting') }"
      >
        <SortingExpandable
          v-model="handleSortingOptions"
          :excluded-sorting-options="excludedSortingOptions"
        />
      </BaseExpansionPanel>

      <BaseSeparator v-if="includeSorting" />
    </div>

    <div class="tw-flex tw-justify-between">
      <div />
      <div class="tw-flex tw-gap-2 tw-p-2">
        <slot name="reset-button">
          <BaseButton
            new-design
            :label="getLocaleText('expandable_filters.reset')"
            @click="reset"
          />
        </slot>
        <slot
          v-if="showApply"
          name="apply-button"
        >
          <BaseButton
            new-design
            color="primary"
            class="e2e-apply-filters"
            :label="getLocaleText('expandable_filters.apply')"
            @click="apply"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { debounce } from "lodash-es";
import { computed, inject, onMounted, reactive, ref, watch } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import {
  BaseButton,
  BaseExpansionPanel,
  BaseSeparator,
} from "shared/components/base";
import AuthorsExpandable from "shared/components/selectors/expandables/AuthorsExpandable.vue";
import ContentExpandable from "shared/components/selectors/expandables/ContentExpandable.vue";
import DropdownTags from "shared/components/selectors/expandables/DropdownTags.vue";
import ReactScoreExpandable from "shared/components/selectors/expandables/ReactScoreExpandable.vue";
import SocialImpactExpandable from "shared/components/selectors/expandables/SocialImpactExpandable.vue";
import SocialPlatformsExpandable from "shared/components/selectors/expandables/SocialPlatformsExpandable.vue";
import SortingExpandable from "shared/components/selectors/expandables/SortingExpandable.vue";
import SourcesExpandable from "shared/components/selectors/expandables/SourcesExpandable.vue";
import MediaSelector from "shared/components/selectors/MediaSelector.vue";
import { streamTypes } from "shared/constants";
import { toSentence } from "shared/helpers/array";
import DateRange from "shared/helpers/DateRange";
import {
  getEnabledSocialPlatformsForStream,
  getSources,
  MediumName,
  MediumPlatform,
  social,
  SocialPlatform,
  sourceByMedium,
} from "shared/helpers/media";
import type { SortOptionField } from "shared/helpers/mentions";
import {
  excludedMediaItemSortFields,
  excludedTranscriptRequestSortFields,
  getSortOptions,
} from "shared/helpers/mentions";
import StreamFilters from "shared/helpers/StreamFilters";
import features from "shared/services/features";
import type {
  MediaAndTranscriptRequestQuery,
  Nullable,
  Stream,
} from "shared/types";
import {
  isMediaRequestQuery,
  isStream,
  isTranscriptRequestQuery,
} from "shared/types/guards";
import { MentionType } from "shared/types/mentions";
import { isSocialMentionType } from "shared/types/mentions/guards";

export interface ExpandableFiltersSortOption {
  sortBy: SortOptionField;
  orderBy: "asc" | "desc";
}

export interface ExpandableFiltersProps {
  stream: Stream | MediaAndTranscriptRequestQuery;
  dateRange?: DateRange;
  filters?: Nullable<StreamFilters>;
  sortingOptions?: ExpandableFiltersSortOption[];
  noPaddingX?: boolean;
  showMultiple?: boolean;
  includeSorting?: boolean;
  stickFilters?: boolean;
  hideDateRange?: boolean;
  showCategories?: boolean;
  showIncludedMentions?: boolean;
  showLastSavedToggle?: boolean;
  showTiers?: boolean;
  showApply?: boolean;
}

interface ExpandedPanels {
  [key: string]: boolean;
}

const $isMobile = inject("isMobile");

const props = withDefaults(defineProps<ExpandableFiltersProps>(), {
  dateRange: () => DateRange.today(),
  filters: null,
  sortingOptions: () => [],
});

const emit = defineEmits<{
  "update:sortingOptions": [ExpandableFiltersSortOption[]];
  "update:filteringContent": [boolean];
  "update:filteringReactScore": [boolean];
  "update:filteringSources": [boolean];
  "update:filteringTags": [boolean];
  "update:filteringExcludedTags": [boolean];
  "update:filteringHandleOrSocialImpact": [boolean];
  "update:filteringLocations": [boolean];
  "update:filteringMedia": [boolean];
  "update:filteringPlatforms": [boolean];
  "update:filteringSocialImpact": [boolean];
  "update:filters": [StreamFilters];
  search: [Record<string, any>];
  reset: [StreamFilters];
  apply: [StreamFilters];
}>();

const localFilters = ref(
  new StreamFilters(isStream(props.stream) ? props.stream : null)
);

const refreshMedia = ref(true);
const refreshPlatforms = ref(true);

const expandedPanels = reactive<ExpandedPanels>({
  content: true,
  reactScore: false,
  sources: false,
  platforms: false,
  media: false,
  socialImpact: false,
  tags: false,
  sorting: false,
});

const enabledPlatforms = computed<MediumPlatform[]>(() =>
  isStream(props.stream) ? getEnabledSocialPlatformsForStream(props.stream) : []
);

const handlePlatforms = computed<SocialPlatform[]>({
  get: () => localFilters.value.platforms,
  set: (value: SocialPlatform[]) => {
    localFilters.value.platforms =
      enabledPlatforms.value.length === value.length ? [] : value;
  },
});

const handleSortingOptions = computed<ExpandableFiltersSortOption[]>({
  get: () => props.sortingOptions,
  set: (val: Array<any>) => {
    emit("update:sortingOptions", val);
  },
});

const excludedSortingOptions = computed<SortOptionField[]>(() => {
  if (isMediaRequestQuery(props.stream) && props.stream.isMediaItems) {
    return excludedMediaItemSortFields;
  }

  if (
    isTranscriptRequestQuery(props.stream) &&
    props.stream.isTranscriptRequest
  ) {
    return excludedTranscriptRequestSortFields;
  }

  return [];
});

const isSocialStream = computed<boolean>(() => {
  if (!isStream(props.stream)) {
    return false;
  }

  return (
    props.stream.type === streamTypes.socialStream ||
    props.stream.social_bookmarks_stream
  );
});

const expandableGroupLabel = computed<string | undefined>(() =>
  props.showMultiple ? undefined : `${props.stream.id}-expandables`
);

type SortingByPossibilities = {
  [key in SortOptionField]: string;
};

const sortingBy = computed<string[]>(() => {
  if (props.sortingOptions.length === 1) return [];

  const possibilities: SortingByPossibilities = getSortOptions().reduce(
    (obj, possibility) => ({
      ...obj,
      [possibility.field]: possibility.label,
    }),
    {} as SortingByPossibilities
  );

  return props.sortingOptions.map((option) => possibilities[option.sortBy]);
});

const filteringByContent = computed<string[]>(() => {
  const filters = [];

  if (localFilters.value.keywords && localFilters.value.keywords !== "") {
    filters.push(getLocaleText("expandable_filters.keywords"));
  }

  if (
    localFilters.value.excludedKeywords &&
    localFilters.value.excludedKeywords !== ""
  ) {
    filters.push(getLocaleText("expandable_filters.excluded_keywords"));
  }

  if (localFilters.value.wordCountEnabled) {
    filters.push(getLocaleText("expandable_filters.word_count"));
  }

  if (localFilters.value.dateRangeEnabled) {
    filters.push(getLocaleText("expandable_filters.date"));
  }

  if (localFilters.value.sentimentEnabled) {
    filters.push(getLocaleText("expandable_filters.sentiment"));
  }

  if (localFilters.value.lastSavedEnabled) {
    filters.push(getLocaleText("expandable_filters.last_saved"));
  }

  if (localFilters.value.externalItemsEnabled) {
    filters.push(getLocaleText("expandable_filters.external_items"));
  }

  if (localFilters.value.includedMentionsEnabled) {
    filters.push(getLocaleText("expandable_filters.included_mentions"));
  }

  return filters;
});

const filteringBySources = computed<string[]>(() => {
  const filters = [];

  if (localFilters.value.sources.length) {
    filters.push(getLocaleText("expandable_filters.sources"));
  }

  if (localFilters.value.excludedSources.length) {
    filters.push(getLocaleText("expandable_filters.excluded_sources"));
  }

  if (localFilters.value.authors.length) {
    filters.push(getLocaleText("expandable_filters.authors"));
  }

  if (localFilters.value.categories.length) {
    filters.push(getLocaleText("expandable_filters.categories"));
  }

  if (localFilters.value.tiers && localFilters.value.tiers.length) {
    filters.push(getLocaleText("expandable_filters.tiers"));
  }

  return filters;
});

const filteringByTags = computed<string[]>(() => {
  const filters = [];

  if (localFilters.value.tags.length) {
    filters.push(getLocaleText("expandable_filters.tag").toLowerCase());
  }

  if (localFilters.value.excludedTags.length) {
    filters.push(
      getLocaleText("expandable_filters.excluded_tags").toLowerCase()
    );
  }

  return filters;
});

const defaultEnabledMedia = computed<MediumName[]>(() =>
  getSources()
    .filter(({ medium }) => {
      if (isStream(props.stream)) {
        return props.stream.enabled_media.includes(medium);
      }

      return false;
    })
    .map((medium) => medium.medium)
);

const defaultEnabledSocialMediaTypes = computed<MentionType[]>(() =>
  [...social.relatedTypes]
    .filter((type) => {
      if (isStream(props.stream) && isSocialMentionType(type.field)) {
        return (
          props.stream.selected_social_content &&
          props.stream.selected_social_content.includes(type.field)
        );
      }

      return false;
    })
    .map((type) => type.field)
);

const filteringByMedia = computed(() => {
  if (!localFilters.value.media.length) return [];

  return defaultEnabledMedia.value
    .filter((medium) => !localFilters.value.media.includes(medium))
    .map((medium) => sourceByMedium[medium].label);
});

const filteringByPlatforms = computed<string[]>(() => {
  if (!localFilters.value.platforms.length) return [];

  const platformLabels: string[] = [];

  [...social.platforms].forEach((platform) => {
    const relevantPlatform = defaultEnabledSocialMediaTypes.value.some(
      (mediaType) =>
        isSocialMentionType(mediaType) && platform.fields.includes(mediaType)
    );

    if (
      relevantPlatform &&
      !localFilters.value.platforms.includes(platform.platform)
    ) {
      platformLabels.push(platform.label);
    }
  });

  return platformLabels;
});

const filteringByHandleOrSocialImpact = computed<string[]>(() => {
  const filters = [];

  const {
    authors,
    verifiedEnabled,
    minimumFollowerCountEnabled,
    minimumFollowerCount,
  } = localFilters.value;

  if (verifiedEnabled) {
    filters.push(getLocaleText("expandable_filters.verified").toLowerCase());
  }

  if (minimumFollowerCountEnabled && minimumFollowerCount) {
    filters.push(getLocaleText("expandable_filters.minimum_followers"));
  }

  if (authors.length) filters.push(getLocaleText("expandable_filters.handle"));

  return filters;
});

const filteringByLocations = computed<string[]>(() => {
  if (localFilters.value.locations.length) {
    return [getLocaleText("expandable_filters.location").toLowerCase()];
  }

  return [];
});

const filteringByReactScore = computed(() => {
  const filters = [];

  if (localFilters.value.filteringReactScore()) {
    if (localFilters.value.reactScoreAverageSelected)
      filters.push(getLocaleText("expandable_filters.react_score_average"));
    else filters.push(getLocaleText("expandable_filters.risk_categories"));
  }

  return filters;
});

function displayFiltering(tab: string): string {
  if (expandedPanels[tab] && !props.showMultiple) {
    return "";
  }

  let filters: string[] = [];
  let prefix = "";

  switch (tab) {
    case "content":
      filters = filteringByContent.value;
      prefix = getLocaleText("expandable_filters.filtering_by");
      break;
    case "reactScore":
      filters = filteringByReactScore.value;
      prefix = getLocaleText("expandable_filters.filtering_by");
      break;
    case "sources":
      filters = filteringBySources.value;
      prefix = getLocaleText("expandable_filters.filtering_by");
      break;
    case "tags":
      filters = filteringByTags.value;
      prefix = getLocaleText("expandable_filters.filtering_by");
      break;
    case "locations":
      filters = filteringByLocations.value;
      prefix = getLocaleText("expandable_filters.filtering_by");
      break;
    case "media":
      filters = filteringByMedia.value;
      prefix = getLocaleText("expandable_filters.exclude");
      break;
    case "platforms":
      filters = filteringByPlatforms.value;
      prefix = getLocaleText("expandable_filters.exclude");
      break;
    case "handleOrSocialImpact":
      filters = filteringByHandleOrSocialImpact.value;
      prefix = getLocaleText("expandable_filters.filtering_by");
      break;
    case "sorting":
      filters = sortingBy.value;
      prefix = getLocaleText("expandable_filters.sorting_by");
      break;
    default:
  }

  return filters.length ? `${prefix} ${toSentence(filters)}` : "";
}

const displayContent = computed(() => {
  emit("update:filteringContent", filteringByContent.value.length > 0);

  return displayFiltering("content");
});

const displaySources = computed(() => {
  emit("update:filteringSources", filteringBySources.value.length > 0);

  return displayFiltering("sources");
});

const displayTags = computed(() => {
  emit("update:filteringTags", filteringByTags.value.length > 0);

  return displayFiltering("tags");
});

const displayMedia = computed(() => {
  emit("update:filteringMedia", filteringByMedia.value.length > 0);

  return displayFiltering("media");
});

const displayPlatforms = computed(() => {
  emit("update:filteringPlatforms", filteringByPlatforms.value.length > 0);

  return displayFiltering("platforms");
});

const displayHandleOrSocialImpact = computed(() => {
  emit(
    "update:filteringHandleOrSocialImpact",
    Boolean(filteringByHandleOrSocialImpact.value.length)
  );

  return displayFiltering("handleOrSocialImpact");
});

const displaySorting = computed(() => displayFiltering("sorting"));

const showReactScoreFilters = computed(
  () => !isSocialStream.value && features.has("has_react_score")
);

const displayReactScore = computed(() => {
  emit("update:filteringReactScore", filteringByReactScore.value.length > 0);

  return displayFiltering("reactScore");
});

const stickyFilters = computed<boolean>(() =>
  Boolean($isMobile && props.stickFilters)
);

function searchParams() {
  return localFilters.value.requestFilters();
}

function search() {
  const params = searchParams();
  emit("update:filters", localFilters.value);
  emit("search", params);
}

const debouncedSearch = debounce(search, 600);

function reset() {
  const stream = isStream(props.stream) ? props.stream : null;

  const filters = new StreamFilters(
    stream,
    props.filters?.initialFilters ?? {}
  );

  emit("update:filters", filters);
  refreshMedia.value = true;
  refreshPlatforms.value = true;
  emit("reset", filters);
}

function apply() {
  debouncedSearch();
  emit("apply", localFilters.value);
}

function applySearch() {
  if (props.showApply) return;
  apply();
}

function displayActive(tab: string) {
  return props.showMultiple || expandedPanels[tab];
}

watch(
  () => props.filters,
  () => {
    if (!props.filters) return;

    refreshMedia.value = true;
    localFilters.value = props.filters.clone();

    if (!localFilters.value.range) {
      localFilters.value.range = props.dateRange;
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => localFilters.value.includedMentionsEnabled,
  () => {
    emit("update:filters", localFilters.value);
  }
);

onMounted(() => {
  if (props.filters) {
    Object.assign(localFilters.value, props.filters.requestFilters());
  } else {
    localFilters.value.media = defaultEnabledMedia.value;
  }

  emit("update:filters", localFilters.value);
});
</script>

<style lang="scss" scoped>
.show-title-header {
  margin-top: calc($navbar-height + env(safe-area-inset-top));

  .header {
    max-height: calc(100vh - #{$navbar-height} - 42px);
    overflow: auto;
  }
}
</style>
